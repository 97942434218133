<div class="chain-setup-header" [ngClass]="{ publish: !saveBtnLabel }">
  <div class="head">
    <div class="logo" *ngIf="isSupplyChainSetup || isPublication">
      <img src="../../../../assets/logos/RespectSaasLarger-Black.svg" />
    </div>
    <div class="buttons {{ isChainBuilder ? 'justify-content-between' : 'justify-content-end' }}">
      <app-rounded-button
        color="secondary-outline"
        [button_text]="'Go Back'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-left-long'"
        [size]="'sm'"
        [icon_left]="true"
        *ngIf="isChainBuilder"
        (click)="goBack()"
      >
      </app-rounded-button>

      <div class="button-holder">
        <app-rounded-button
          color="secondary-outline"
          [button_text]="'Preview Publication'"
          [icon_category]="'security'"
          [icon_name]="'eye'"
          [size]="'md'"
          [icon_right]="true"
          (click)="preview.emit(true)"
        >
        </app-rounded-button>
        <app-rounded-button
          color="primary-outline"
          [button_text]="'Publish the chain'"
          [size]="'sm'"
          *ngIf="isChainBuilder && !isBatchManagementRoute"
          (click)="publish.emit(true)"
        >
        </app-rounded-button>
      </div>

      <app-rounded-button
        color="secondary-outline"
        [button_text]="isPublication ? 'Close the publication' : 'Cancel'"
        [size]="'md'"
        *ngIf="!isChainBuilder && !isSupplyChainSetup"
        (click)="goToDashboard()"
      >
      </app-rounded-button>

      <app-rounded-button
        color="secondary-outline"
        [button_text]="'Cancel'"
        [size]="'md'"
        [icon_right]="isPublication"
        [icon_category]="'grid'"
        [icon_name]="'element-2'"
        *ngIf="isSupplyChainSetup"
        (click)="goToDashboard()"
      >
      </app-rounded-button>
      <app-rounded-button
        color="secondary"
        [button_text]="saveBtnLabel ? saveBtnLabel : 'Save & Close'"
        [size]="'md'"
        *ngIf="!isChainBuilder && !isPublication && !isSupplyChainSetup"
        (click)="saveAndClose.emit(true)"
      >
      </app-rounded-button>
    </div>

    <div class="icon" *ngIf="icon">
      <app-custom-icon
        [icon_category]="'weather'"
        [icon_name]="icon"
        [matTooltip]="icon === 'saving' ? 'changes not saved yet' : 'All changes saved'"
        matTooltipClass="custom-tooltip-end mt-20"
        [matTooltipPosition]="'below'"
      >
      </app-custom-icon>
    </div>
  </div>
</div>
