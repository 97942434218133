import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';
import { emailSent, helperText, requestTypes, successDialogText } from './data-privacy.constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { ISubjectRight } from '@app/core/interface/brands.interface';
import { IUser } from '@app/core/interface/login.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RequestCreationComponent } from './request-creation/request-creation.component';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrl: './data-privacy.component.scss',
})
export class DataPrivacyComponent implements OnInit {
  user: IUser | null = this._dataAuthService.user;
  displayedColumns: string[] = ['request_type', 'created_at', 'status'];
  requestTypes = requestTypes;
  helperText = helperText;
  emailSentText = emailSent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  tableDataSource = new MatTableDataSource<ISubjectRight>();

  constructor(
    private _dialog: MatDialog,
    private _brandsFacade: BrandsFacade,
    private _dataAuthService: DataAuthService,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.user = typeof this.user === 'string' ? JSON.parse(this.user) : this.user;

    if (this.user) {
      this._brandsFacade.getBrandUserSubjectRights$(this.user.uuid).subscribe(data => {
        this.tableDataSource.data = data;
      });
    }
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
    this.paginator.pageSize = 6;
  }

  requestType(value: number): string {
    return requestTypes.find(type => type.value === value)?.label ?? '';
  }

  openRequestCreation() {
    const dialogRef = this._dialog.open(RequestCreationComponent, {
      width: '600px',
      data: this.user,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
        this.showInfoDialog();
      }
    });
  }

  downloadBrandUserData(): void {
    this._brandsFacade.downloadUserData$().subscribe({
      next: ({ blob, filename }) => {
        this._downloadFile(blob, filename);
      },
      error: err => {
        this._error(err.status + ' ' + err.statusText);
      },
    });
  }

  showInfoDialog(): void {
    this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        descriptionText: successDialogText,
        btnText: 'Ok, got it',
        type: 'forbidden-info',
        text: 'info-text',
      },
    });
  }

  ///////////////////////////PRIVATE METHODS///////////////////////////

  private _downloadFile(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename.endsWith('.csv') ? filename : `${filename}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    this._snackbarService.openTypeSnackbar('Your data has been successfully downloaded.', NotificationType.success);
  }

  private _error(error: string): void {
    this._snackbarService.openTypeSnackbar(error, NotificationType.error);
  }
}
