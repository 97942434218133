<div class="row">
  <div *ngIf="!data?.supplier?.is_hidden" class="col">
    <div>
      <h3 class="modal-step__title alt-black">
        {{ data?.supplier?.name }}
      </h3>
      <div class="row">
        <div class="col-3">
          <img *ngIf="data?.supplier?.logo" class="img-logo" src="{{ data?.supplier?.logo }}" />
        </div>
        <!---->
        <div
          [ngClass]="{ 'col-6': data?.supplier?.logo, 'col-12': !data?.supplier?.logo }"
          class="d-flex flex-column align-items-start justify-content-center"
        >
          <div>
            <p class="modal-step_location mb-1">{{ data?.supplier?.manufactory?.address }}</p>
            <p class="modal-step_location mb-1">{{ data?.supplier?.manufactory?.city }}</p>
            <p class="modal-step_location">
              {{ data?.supplier?.country ? getCountryName(data?.supplier?.country) : 'Not specified' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="modal-step__description" [innerHTML]="data?.supplier?.description"></p>
    <div *ngIf="images && images?.length !== 0" class="row">
      <div class="col">
        <div class="modal-step__label mb-1">
          {{ 'Media Gallery' | translate }}
        </div>
        <section class="modal-step__gallery">
          <div *ngIf="images.length >= 3" class="carousels">
            <!-- TODO: Carousels to be added. -->
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
              <div ngxSlickItem *ngFor="let slide of images" class="slide">
                <div (click)="onImageDialog(images, slide)">
                  <img
                    src="{{ slide.thumb }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-swiper__item__img cursor-pointer"
                  />
                </div>
              </div>
            </ngx-slick-carousel> -->
          </div>
          <!-- Remove and commented:"  *ngIf="images.length < 3" " we will implement ngx-slick-carousel -->
          <div class="carousels-flex">
            <div class="d-flex">
              <div *ngFor="let slide of images" class="slide">
                <div (click)="onImageDialog(images, slide)">
                  <img
                    src="{{ slide.thumbnail }}"
                    alt="mediaImg"
                    width="200px"
                    height="200px"
                    class="media-item__img cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div *ngIf="videos && videos?.length !== 0" class="row">
      <div class="col">
        <div class="modal-step__label mb-1">
          {{ 'Video' | translate }}
        </div>
        <section class="modal-step__gallery">
          <div *ngIf="videos.length >= 3" class="carousels">
            <!-- TODO: Carousels to be added. -->
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigVideo">
              <div ngxSlickItem *ngFor="let slide of videos" class="slide">
                <div (click)="onVideoDialog(slide.file)">
                  <img
                    src="{{ slide.medium }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-swiper__item__img cursor-pointer"
                  />
                </div>
              </div>
            </ngx-slick-carousel> -->
          </div>
          <!-- Remove and commented:" *ngIf="videos.length < 3" " we will implement ngx-slick-carousel -->
          <div class="carousels-flex">
            <div class="d-flex">
              <div *ngFor="let slide of videos" class="slide">
                <div (click)="onVideoDialog(slide.file)">
                  <img
                    src="{{ slide.thumbnail }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-item__img cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div *ngIf="data?.supplier && data?.supplier?.certifications?.length !== 0" class="row">
      <div class="col-12">
        <div class="modal-step__label mb-1">
          {{ 'Certification' | translate }}
        </div>
      </div>
      <div class="col-12 d-flex">
        <p
          *ngFor="let certification of data?.supplier?.certifications"
          (click)="onCertificateDialog(certification)"
          class="cursor-pointer"
        >
          <!-- {{ certification.certificate?.name }}; &nbsp; -->
          <img class="certificate-logo" src="{{ certification.certificate?.image }}" />
        </p>
      </div>
    </div>

    <div *ngIf="data?.supplier && data?.supplier?.links?.length !== 0" class="row">
      <div class="col-12">
        <div class="modal-step__label mb-1">
          {{ 'Links' | translate }}
        </div>
      </div>
      <div class="col-12 d-flex">
        <a *ngFor="let link of data?.supplier?.links" href="{{ link.url }}" target="_blank">
          {{ link.name }}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="data?.supplier?.is_hidden" class="col">{{ 'Confidential supplier' | translate }}</div>
</div>
