import { Injectable } from '@angular/core';
import { CustomPublicationsService } from '@module/publication/custom-publications/custom-publications.service';
import { catchError, Observable, ObservableInput, switchMap, take, throwError } from 'rxjs';
import {
  ICustomPublication,
  ICustomPublicationType,
  ISupplierCertificatesResponse,
} from '@module/publication/custom-publications/custom-publications.models';
import {
  ICreatePublicationStep,
  IPublicationErrorResponse,
  IPublicationGroup,
  IPublicationType,
} from '@interface/publication.interface';
import { generateFormData } from '@app/core/utils/generate-formData';
import { IProductChainStep } from '@interface/steps.interface';
import { StepsService } from '@service/steps.service';
import { IManufactory } from '@interface/manufactories.interface';
import { ManufactoriesService } from '@service/manufactories.service';
import { ISupplier, ISupplierResponse } from '@interface/suppliers.interface';
import { SuppliersService } from '@service/suppliers.service';
import { IProductChainResult } from '@interface/productchain.interface';
import { IProductGroup } from '@interface/products.interface';

@Injectable()
export class CustomPublicationsFacade {
  constructor(
    private _service: CustomPublicationsService,
    private _stepService: StepsService,
    private _manufactoryService: ManufactoriesService,
    private _suppliersService: SuppliersService
  ) {}

  getPublication$(publication_uuid: string): Observable<ICustomPublication> {
    return this._service.getPublication(publication_uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ICustomPublication> => throwError(() => err.error))
    );
  }

  addStepToClassification(
    uuid: string,
    type_step?: string,
    group_uuid?: string,
    isRemove = false
  ): Observable<string[]> {
    let formData;
    if (isRemove) {
      formData = generateFormData({ group: group_uuid, type_step });
    } else {
      formData = group_uuid
        ? generateFormData({ group: group_uuid, type_step })
        : generateFormData({ type_step, group: '' });
    }
    return this._service.addStepToClassification(formData, uuid).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }

  addCustomFieldToGroup(custom_field: string, uuid: string): Observable<string[]> {
    const formData = generateFormData({ custom_field });
    return this._service.addCustomFieldToGroup(formData, uuid).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }

  createPublicationGroup$(data: Partial<IPublicationGroup>): Observable<IPublicationGroup> {
    const formData = generateFormData(data);
    return this._service.createGroup$(formData).pipe(
      take(1),
      catchError(({ error }: IPublicationGroup): ObservableInput<IPublicationGroup> => throwError(() => error))
    );
  }

  updatePublicationGroup$(data: Partial<IPublicationGroup>, group_uuid: string): Observable<IPublicationGroup> {
    const formData = generateFormData(data);
    return this._service.updateGroup$(formData, group_uuid).pipe(
      take(1),
      catchError(({ error }: IPublicationGroup): ObservableInput<IPublicationGroup> => throwError(() => error))
    );
  }

  deletePublicationGroup(group_uuid: string): Observable<IPublicationGroup> {
    return this._service.deleteGroup(group_uuid).pipe(
      take(1),
      catchError(({ error }: IPublicationGroup): ObservableInput<IPublicationGroup> => throwError(() => error))
    );
  }

  getStep$(uuid: string, isBatchStep?: boolean): Observable<IProductChainStep> {
    return this._stepService.getStep$(uuid, isBatchStep).pipe(
      take(1),
      catchError((err): ObservableInput<IProductChainStep> => throwError(() => err.error))
    );
  }

  getSupplier$(uuid: string): Observable<ISupplierResponse> {
    return this._suppliersService.getSupplier$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISupplierResponse> => throwError(() => err.error))
    );
  }

  getSupplierCertificates$(uuid: string): Observable<ISupplierCertificatesResponse> {
    return this._service.getSupplierCertificates$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISupplierCertificatesResponse> => throwError(() => err.error))
    );
  }

  createStep$(data: Partial<unknown>, product_chain: string): Observable<IProductChainStep> {
    const formData = generateFormData(data);
    formData.append('product_chain', product_chain);
    return this._stepService.createStep$(formData).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  updateStep$(data: Partial<unknown>, step_uuid: string): Observable<IProductChainStep> {
    const formData = generateFormData(data);
    return this._stepService.updateStep$(formData, step_uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  createPublicationManufactory$(data: Partial<IManufactory>): Observable<IManufactory> {
    const formData = generateFormData(data);
    return this._manufactoryService.createSupplierSite$(formData).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }

  updatePublicationManufactory$(data: Partial<IManufactory>, manufactory_uuid: string): Observable<IManufactory> {
    const formData = generateFormData(data);
    return this._manufactoryService.updateSupplierSite$(manufactory_uuid, formData).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }

  createStepSupplierManufactory$(
    uuid: string,
    changedStepFormValues: Partial<ICreatePublicationStep>,
    changedSupplierFormValues: Partial<ISupplier>,
    changedManufactoryFormValues: Partial<IManufactory>
  ): Observable<IProductChainStep> {
    let newSupplierUuid = '';
    return this.createPublicationSupplier$({ ...changedSupplierFormValues }).pipe(
      switchMap(res => {
        newSupplierUuid = res.uuid;
        return this.createPublicationManufactory$({ ...changedManufactoryFormValues, supplier: res.uuid });
      }),
      switchMap((res: IManufactory) => {
        const body = { ...changedStepFormValues, supplier: newSupplierUuid, manufactories: res.uuid };
        return this.createStep$(body, uuid);
      })
    );
  }

  createStepAndSupplier$(
    uuid: string,
    changedStepFormValues: Partial<ICreatePublicationStep>,
    changedSupplierFormValues: Partial<ISupplier>
  ): Observable<IProductChainStep> {
    return this.createPublicationSupplier$({ ...changedSupplierFormValues }).pipe(
      switchMap(res => {
        return this.createStep$({ ...changedStepFormValues, supplier: res.uuid }, uuid);
      })
    );
  }

  createPublicationSupplier$(data: Partial<ISupplier>): Observable<ISupplier> {
    const formData = generateFormData(data);
    return this._suppliersService.createSupplier$(formData).pipe(
      take(1),
      catchError(({ error }: ISupplier): ObservableInput<ISupplier> => throwError(() => error))
    );
  }

  updatePublicationSupplier(data: Partial<ISupplier>, supplier_uuid: string): Observable<ISupplier> {
    const formData = generateFormData(data);
    return this._suppliersService.updateSupplier$(supplier_uuid, formData).pipe(
      take(1),
      catchError(({ error }: ISupplier): ObservableInput<ISupplier> => throwError(() => error))
    );
  }

  publish(productChainUUid: string): Observable<IProductChainResult> {
    return this._service.publishProductChain$(productChainUUid).pipe(
      take(1),
      catchError(({ error }: ISupplier): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }

  attachProductGroup(productUuid: string, product_group_uuid: string): Observable<IProductGroup> {
    return this._service.attachProductGroup(productUuid, product_group_uuid).pipe(
      take(1),
      catchError(({ error }: IProductGroup): ObservableInput<IProductGroup> => throwError(() => error))
    );
  }

  createClassification(data: Partial<IPublicationType>, productChainUid: string): Observable<ICustomPublicationType> {
    return this._service.createClassification(productChainUid, data).pipe(
      take(1),
      catchError(
        ({ error }: ICustomPublicationType): ObservableInput<ICustomPublicationType> => throwError(() => error)
      )
    );
  }

  updateClassification(
    data: Partial<IPublicationType>,
    productChainUid: string,
    typeStepUid: string
  ): Observable<ICustomPublicationType> {
    return this._service.updateClassification(productChainUid, typeStepUid, data).pipe(
      take(1),
      catchError(
        ({ error }: ICustomPublicationType): ObservableInput<ICustomPublicationType> => throwError(() => error)
      )
    );
  }

  deleteClassification(productChainUid: string, typeStepUid: string): Observable<ICustomPublicationType> {
    return this._service.deleteClassification(productChainUid, typeStepUid).pipe(
      take(1),
      catchError(
        ({ error }: ICustomPublicationType): ObservableInput<ICustomPublicationType> => throwError(() => error)
      )
    );
  }

  updateClassificationsPosition$(data: string[]): Observable<string[]> {
    return this._service.updateClassificationsPosition$(data).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }

  updateGroupPositions$(data: string[]): Observable<string[]> {
    const formData = generateFormData({ group: data }, true);
    return this._service.updateGroupPositions$(formData).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }
}
