<div
  *ngIf="isToggleSet || !leftSidenavOpen"
  class="toggle-sidenav"
  [ngClass]="{ closed: !leftSidenavOpen }"
  (click)="sidenavLeft.toggle(); leftSidenavOpen = !leftSidenavOpen"
  (mouseover)="setToggle(true)"
  (mouseleave)="setToggle(false)"
  [matTooltip]="leftSidenavOpen ? ('Collapse' | translate) : ('Expand' | translate)"
  matTooltipPosition="left"
  matTooltipClass="custom-tooltip-center"
  matTooltipVisibility="visible"
>
  <app-custom-icon
    [icon_category]="'arrows'"
    [icon_name]="leftSidenavOpen ? 'arrow-left-short' : 'arrow-right-short'"
  ></app-custom-icon>
</div>

<mat-sidenav-container class="main-container">
  <mat-sidenav #sidenavLeft mode="side" opened="{{ isOpen }}" class="left-sidenav" (openedChange)="isCollapsed($event)">
    <div (mouseover)="setToggle(true)" (mouseleave)="setToggle(false)">
      <app-chain-sidenav
        [chainData]="selectedChain"
        [phases]="phases"
        [supplierList]="supplierList"
        [batchSetup]="batchSetup"
        [isChainComplete]="isChainComplete"
        [product_chain_uuid]="product_chain_uuid"
        (getSupplierList)="getSupplierList()"
        (fetchProductchain)="fetchProductChain()"
        (saveChain)="saveChain()"
        (onUpdateBatchStatus)="updateBatchStatus(false)"
        (refresh)="refresh()"
      >
      </app-chain-sidenav>
    </div>
  </mat-sidenav>

  <mat-sidenav-content [ngClass]="'mat-sidenav-content'">
    <app-chain-setup-header
      [ngClass]="{ 'sidenav-closed': !leftSidenavOpen }"
      [isFormValid]="true"
      [icon]="savingChain ? 'saving' : 'saved-changes'"
      [isChainBuilder]="true"
      (saveAndClose)="saveChain()"
      (publish)="publish()"
      (preview)="preview()"
      [isChainComplete]="isChainComplete"
    >
    </app-chain-setup-header>
    <mat-sidenav-container class="example-container" (backdropClick)="close()">
      <mat-sidenav-content>
        <div class="header-section">
          <div class="header-info">
            <div class="header-progress">
              <app-progress-bar
                [title]="'Steps progress by suppliers'"
                [steps]="selectedChain?.steps"
              ></app-progress-bar>
            </div>
            <div class="header-actions" *ngIf="!isChainComplete">
              <app-rounded-button
                class="clear-button"
                color="tetriary-link"
                [button_text]="'Clear all'"
                [size]="'md'"
                (click)="clearAll()"
                *ngIf="!batchSetup"
              >
              </app-rounded-button>
              <app-rounded-button
                color="success"
                [button_text]="'Add Step'"
                [icon_category]="'essential'"
                [icon_name]="'add'"
                [size]="'md'"
                [icon_right]="true"
                (click)="addStep()"
                *ngIf="!batchSetup"
              >
              </app-rounded-button>
            </div>
            <div *ngIf="batchSetup && has_inactive_steps && !isChainComplete" class="checkbox">
              <mat-checkbox
                (change)="showDeactivatedSteps($event)"
                [checked]="show_deactivated"
                class="checkbox-primary"
              >
                {{ 'Show deactivated steps' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="horizontal-line"></div>
          <!-- Temporary hide Cluster section -->
          <!-- <div class="cluster-section">
            <div class="cluster-info">
              <img
                src="../../../../assets/images/help-icon.svg"
                matTooltip="{{ 'Clusters are \n group of steps' | translate }}"
                matTooltipClass="custom-tooltip-center"
                matTooltipPosition="above"
              />
              <h6>{{ 'Your clusters ' }}</h6>
              <div class="cluster-legend">
                <div
                  *ngFor="let cluster of selectedChain?.groups | slice: 0 : displayClusters; let i = index"
                  class="legend-container justify-content-start"
                  (click)="!batchSetup && createCluster(cluster); !batchSetup && saveChain()"
                >
                  <div class="color-container">
                    <div class="legend-color" [style.backgroundColor]="cluster.color"></div>
                  </div>
                  <div class="text-icon-holder">
                    <p class="legend-text">{{ cluster.name }}</p>
                    <app-custom-icon
                      [icon_category]="'files'"
                      [icon_name]="'edit-2'"
                      *ngIf="!batchSetup && !isChainComplete"
                      matTooltip="{{ 'Edit Cluster' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      [matTooltipPosition]="'above'"
                    >
                    </app-custom-icon>
                  </div>
                </div>
                <div class="dots" *ngIf="selectedChain?.groups && (selectedChain?.groups)!.length > displayClusters!">
                  <app-custom-icon
                    id="dots"
                    [icon_category]="'settings'"
                    [icon_name]="'more'"
                    matTooltip="{{ 'Click to view \n more clusters' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    [matMenuTriggerFor]="beforeMenu"
                  >
                  </app-custom-icon>

                  <mat-menu #beforeMenu="matMenu" xPosition="before" class="clusters-menu">
                    <div class="menu-wrapper" (click)="$event.stopPropagation()">
                      <button
                        class="custom-item"
                        mat-menu-item
                        *ngFor="let cluster of selectedChain?.groups | slice: displayClusters!"
                        (click)="!batchSetup && createCluster(cluster); !batchSetup && saveChain()"
                      >
                        <div class="name-holder d-flex">
                          <div class="color-container">
                            <div class="legend-color" [style.backgroundColor]="cluster.color"></div>
                          </div>
                          <p class="legend-text">{{ cluster.name }}</p>
                        </div>

                        <app-custom-icon
                          [icon_category]="'files'"
                          [icon_name]="'edit-2'"
                          *ngIf="!batchSetup && !isChainComplete"
                          matTooltip="{{ 'Edit Cluster' | translate }}"
                          matTooltipClass="custom-tooltip-center"
                          [matTooltipPosition]="'above'"
                        >
                        </app-custom-icon>
                      </button>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div class="cluster-actions">
              <app-rounded-button
                color="tetriary-link"
                [button_text]="'Create Cluster'"
                [icon_category]="'arrows'"
                [icon_name]="'arrow-right-long'"
                [size]="'md'"
                [icon_right]="true"
                (click)="createCluster(); saveChain()"
                *ngIf="!batchSetup && !isChainComplete"
              >
              </app-rounded-button>
            </div>
          </div> -->
        </div>

        <div class="canvas-playground {{ selectedChain?.direction }}" (click)="canvasClick($event)">
          <div id="canvasContainer">
            <ng-template #nodes></ng-template>
          </div>
        </div>

        <mat-card class="options-footer" [ngClass]="{ 'full-width': !leftSidenavOpen }">
          <div class="zoom-buttons">
            <app-custom-icon
              matTooltip="{{ 'Zoom In' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
              [icon_category]="'search'"
              [icon_name]="'search-zoom-in-2'"
              (click)="zoom('+')"
            ></app-custom-icon>
            <app-custom-icon
              matTooltip="{{ 'Zoom Out' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
              [icon_category]="'search'"
              [icon_name]="'search-zoom-out-2'"
              (click)="zoom('-')"
            ></app-custom-icon>
          </div>
        </mat-card>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>

  <mat-sidenav
    disableClose
    #sidenav
    position="end"
    class="right-sidenav"
    [ngClass]="{ 'sidenav-update': selectedStep, 'sidenav-complete': showCompleteStepSidenav }"
  >
    <app-step-update
      *ngIf="selectedStep"
      [phases]="selectedChain?.type_steps"
      [supplierList]="supplierList"
      (closeDialog)="closeDialog($event)"
      [variables]="emailVariables"
      [selectedChain]="selectedChain"
    ></app-step-update>
    <app-step-batch
      *ngIf="showCompleteStepSidenav"
      [phases]="selectedChain?.type_steps"
      [suppliers]="supplierList"
      [isChainComplete]="isChainComplete"
      [variables]="emailVariables"
      (closeDialog)="closeDialog($event)"
    ></app-step-batch>
  </mat-sidenav>
</mat-sidenav-container>
