import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ICustomPublication,
  ICustomPublicationStep,
  ICustomPublicationTypeGroup,
  ICustomPublicationTypeStep,
} from '../../custom-publications/custom-publications.models';

import { Component } from '@angular/core';
import { CountryList } from '@app/core/utils/country-list';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CreateStepComponent } from './create-step/create-step.component';
import { CustomPublicationsFacade } from '../../custom-publications/custom-publications.facade';
import { EditClassificationComponent } from './edit-classification/edit-classification.component';
import { IProductChainStep } from '@app/core/interface/steps.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-setup-chain',
  templateUrl: './setup-chain.component.html',
  styleUrls: ['./setup-chain.component.scss'],
})
export class SetupChainComponent {
  productChainUuid?: string | null;
  getCountryName = CountryList.getCountryName;
  showVideoMsg = true;
  classifications!: ICustomPublicationTypeStep[];
  unclassifiedSteps?: ICustomPublicationStep[] = [];
  productChain!: ICustomPublication;

  constructor(
    private _facade: PublicationFacade,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _route: ActivatedRoute,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.productChainUuid = this._route.snapshot.paramMap.get('id');
    if (this.productChainUuid) {
      this._customPublicationFacade.getPublication$(this.productChainUuid).subscribe({
        next: data => {
          this.classifications = data.type_steps;
          this.unclassifiedSteps = data.unclassified_steps;
          this.productChain = data;
        },
        error: this._error.bind(this),
      });
    }
  }

  dropClassification(event: CdkDragDrop<ICustomPublicationTypeStep[]>) {
    moveItemInArray(this.classifications, event.previousIndex, event.currentIndex);
    this.updateClassificationPostions();
  }

  dropGroup(event: CdkDragDrop<ICustomPublicationTypeGroup[]>, classification: ICustomPublicationTypeStep) {
    if (classification.groups) {
      moveItemInArray(classification.groups, event.previousIndex, event.currentIndex);
      this.updateGroupPositions(classification.groups);
    }
  }

  updateGroupPositions(groups: ICustomPublicationTypeGroup[]): void {
    const groupUpdates: string[] = groups.map(group => group.uuid);
    this._customPublicationFacade.updateGroupPositions$(groupUpdates).subscribe({
      error: this._error.bind(this),
    });
  }

  dropItem(event: CdkDragDrop<ICustomPublicationStep[]>, classificationUuid?: string) {
    if (!event.item.data.chain) return;
    const containerData = event.container.data;
    const previousContainerData = event.previousContainer.data;

    if (containerData && previousContainerData && !event.item.data.steps) {
      if (event.previousContainer === event.container) {
        moveItemInArray(containerData, event.previousIndex, event.currentIndex);
        this.updateStepPostions(containerData);
      } else {
        transferArrayItem(previousContainerData, containerData, event.previousIndex, event.currentIndex);
        const movedItem = containerData[event.currentIndex];

        if (classificationUuid) {
          this.addStep(movedItem, classificationUuid);
        } else {
          this.removeStep(movedItem);
        }
      }
    }
  }

  dropGroupItem(event: CdkDragDrop<ICustomPublicationStep[]>, classificationUuid: string, groupUuid: string) {
    if (!event.item.data.steps && event.container.data && event.previousContainer.data) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.updateStepPostions(event.container.data);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        this.addStep(event.container.data[event.currentIndex], classificationUuid, groupUuid);
      }
    }
  }

  getConnectedListAll(): string[] {
    const ids: string[] = [];
    this.classifications?.map((x: ICustomPublicationTypeStep) => {
      ids.push(`${x.uuid}`);
      x.groups?.map((group: ICustomPublicationTypeGroup) => {
        ids.push(`${x.uuid}-${group.uuid}`);
      });
    });
    ids.push('unclassified');

    return ids;
  }

  updateClassificationPostions(): void {
    const positions: string[] = this.classifications.map(classification => classification.uuid);
    if (this.productChainUuid) {
      this._facade.updateChainClassificationsPosition$(positions).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  editClassification(classification?: ICustomPublicationTypeStep): void {
    const dialogRef = this._dialog.open(EditClassificationComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: { classification, product_chain: this.productChainUuid },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        if (result.mode === 'create') {
          const newArray = [result.data].concat(this.classifications);
          this.classifications = newArray;
          this.updateClassificationPostions();
        }
        this.ngOnInit();
      }
    });
  }

  removeClassification(uuid: string): void {
    if (this.productChainUuid) {
      this._customPublicationFacade.deleteClassification(this.productChainUuid, uuid).subscribe({
        next: () => this.ngOnInit(),
        error: this._error.bind(this),
      });
    }
  }

  addStep(step: ICustomPublicationStep | IProductChainStep, classificationUuid: string, groupUuid?: string): void {
    const updatedStep: {
      type_step?: string;
      group: string;
    } = {
      type_step: classificationUuid,
      group: groupUuid ? groupUuid : '',
    };

    if (this.productChainUuid) {
      this._facade.updatePublicationChainStep$({ ...updatedStep }, step.uuid).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  removeStep(step: ICustomPublicationStep | IProductChainStep): void {
    if (this.productChainUuid) {
      this._facade.updatePublicationChainStep$({ type_step: '', group: '' }, step.uuid).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  updateStepPostions(stepsList: ICustomPublicationStep[]): void {
    const positions: string[] = stepsList.map(step => step.uuid);
    if ((this, this.productChainUuid)) {
      this._facade.updateStepsPosition$(positions).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  createNewStep(): void {
    const dialogRef = this._dialog.open(CreateStepComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['top-padding-0', 'overflow-hidden-dialog'],
      data: { productChainUuid: this.productChainUuid, step: undefined },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  addEditGroup(uuid: string, group?: ICustomPublicationTypeGroup): void {
    const dialogRef = this._dialog.open(CreateGroupComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'top-padding-0',
      data: { productChainUuid: this.productChainUuid, type_step: uuid, group },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  editStep(step: ICustomPublicationStep | IProductChainStep): void {
    const dialogRef = this._dialog.open(CreateStepComponent, {
      width: '1160px',
      height: '860px',
      panelClass: ['top-padding-0', 'overflow-hidden-dialog'],
      data: { productChainUuid: this.productChainUuid, step },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.ngOnInit();
    });
  }

  hideStep(step: ICustomPublicationStep | IProductChainStep): void {
    step.is_hidden = !step.is_hidden;
    if (this.productChainUuid) {
      this._customPublicationFacade.updateStep$({ is_hidden: step.is_hidden }, step.uuid).subscribe({
        next: () => this.ngOnInit(),
        error: this._error.bind(this),
      });
    }
  }
  deleteGroup(uuid: string): void {
    this._facade.deletePublicationGroup$(uuid).subscribe({
      next: () => this.ngOnInit(),
      error: this._error.bind(this),
    });
  }

  goBack(): void {
    this._router.navigate([`/publish/${this.productChainUuid}`], {
      queryParams: { product: this._route.snapshot.queryParamMap.get('product') },
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
