<div class="publication-setup-wrapper d-flex flex-column">
  <div class="header d-flex justify-content-between align-items-center">
    <h6 class="header-text">{{ 'Configure what you want to display for the Publication' | translate }}</h6>
    <div class="custom-button-wrapper">
      <app-rounded-button
        color="primary"
        [icon_category]="'essential'"
        [icon_name]="'publication'"
        [icon_right]="true"
        [button_text]="'Save publication setup'"
        [size]="'mb'"
        matTooltip="{{ 'All publications will be updated' | translate }}"
        matTooltipClass="custom-tooltip-center centering"
        matTooltipPosition="above"
        (click)="onSubmit()"
      >
      </app-rounded-button>
    </div>
  </div>
  <div class="body-wrapper d-flex">
    <!-- Toggle fields -->
    <div class="form-wrapper flex-grow-1 p-0">
      <!-- FORM -->
      <form [formGroup]="form">
        <div class="toggle-wrapper d-flex align-items-center">
          <div class="label">{{ 'Supplier name' | translate }}</div>
          <mat-slide-toggle
            [checked]="!form.get('is_name_private')?.value"
            (change)="onToggleChange('is_name_private', $event)"
          ></mat-slide-toggle>
          <div class="toggle-description d-flex"></div>
        </div>
        <div class="toggle-wrapper d-flex align-items-center">
          <div class="label">{{ 'Supplier description' | translate }}</div>
          <mat-slide-toggle
            [checked]="!form.get('is_description_private')?.value"
            (change)="onToggleChange('is_description_private', $event)"
          ></mat-slide-toggle>
        </div>
        <div class="horizontal-line"></div>
        <div class="select-label">{{ 'Geopositioning Display:' | translate }}</div>
        <div class="select-description">
          {{ 'Please select bellow how your location will be displayed on a map.' | translate }}
        </div>
        <div class="row">
          <mat-form-field class="input-primary p-0" appearance="outline">
            <mat-label>{{ 'Choose Geopositioning' | translate }}</mat-label>
            <mat-select formControlName="selectInput">
              <mat-select-trigger>
                <div>
                  <span class="site-name-blue">{{ form.controls['selectInput'].value.viewValue }}</span>
                  <span class="site-address"> {{ form.controls['selectInput'].value.expVal }}</span>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let option of selectOptions" [value]="option" placeholder="Phase">
                <div>
                  <span class="site-name">
                    {{ option.viewValue }}
                  </span>

                  <span class="site-address"> {{ option.expVal }} </span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>

    <div class="card-wrapper flex-grow-1">
      <mat-card>
        <div class="inner-card-wrapper">
          <div class="image">
            <img src="{{ '../../../../assets/images/brand-avatar.png' }}" alt="" />
          </div>
          <div class="content">
            <h6>{{ 'Check useful data tab' | translate }}</h6>
            <p class="p2-normal">{{ 'to manage the media that will be displayed in publication.' | translate }}</p>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #toggleButton>
  <div class="toggle-wrapper d-flex align-items-center">
    <div class="label">{{ 'Product description' | translate }}</div>
    <mat-slide-toggle></mat-slide-toggle>
  </div>
</ng-template>
