import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ChainStatus,
  IGetConnection,
  INode,
  INodeConnection,
  IProductChainErrorResponse,
  IProductChainLink,
  IProductChainResult,
} from '@app/core/interface/productchain.interface';
import { IPhase, IProductChain } from '@app/core/interface/phase-management.interface';
import { ISupplier, ISuppliersErrorResponse, ISuppliersResponse } from '@app/core/interface/suppliers.interface';
import { NotificationType, Storage, USER, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { BatchFacade } from '@app/core/facade/batch.facade';
import { BatchStatusUpdateComponent } from '@app/module/dashboard/product/batch-status-update/batch-status-update.component';
import { ChartData } from 'chart.js';
import { CreateClusterComponent } from './../create-cluster/create-cluster.component';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IBatchResult } from '@app/core/interface/batch.interface';
import { ICluster } from '@app/core/interface/cluster.interface';
import { IEmailVarValues } from '@app/core/interface/email.interface';
import { IUser } from '@app/core/interface/login.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NodeService } from '@app/core/service/node.service';
import { PhasesFacade } from '@app/core/facade/phases.facade';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { ProductChainGroup } from '../product-chain.group';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SidenavService } from '@app/core/service/sidenav.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepDialogComponent } from '../step-dialog/step-dialog.component';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';
import { IProductResponse } from '@app/core/interface/products.interface';

@Component({
  selector: 'app-node-container',
  templateUrl: './node-container.component.html',
  styleUrls: ['./node-container.component.scss'],
  providers: [StepsFacade, ProductChainFacade, ProductChainGroup, SuppliersFacade, BatchFacade, NodeService],
})
export class NodeContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('nodes', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  phases!: [ChartData<'doughnut'>, IPhase[]];
  nodes: INode[] = [];
  connections: INodeConnection[] = [];
  direction: string;
  selectedChain?: IProductChainResult;
  selectedStep?: INode;
  currentZoom = 1;
  form: UntypedFormGroup;
  isEditChainName = false;
  supplierList?: ISupplier[];
  selectedStepsList: string[] = [];
  leftSidenavOpen = true;
  batchSetup!: boolean;
  showCompleteStepSidenav!: boolean;
  savingChain!: boolean;
  product_chain_uuid!: string | null;
  browserWidth!: number;
  displayClusters?: number;
  isToggleSet = false;
  product!: IProductResponse;
  productUUID!: string;
  emailVariables: IEmailVarValues = {
    'Brand name': null,
    'Brand contact name': null,
    'Supplier name': null,
    'Supplier contact name': null,
    'Product name': null,
    'Project name': null,
    'Current date': null,
  };

  isOpen = false;
  show_deactivated = false;
  has_inactive_steps = false;
  isChainComplete = false;
  isCollapsedSideNav!: boolean;
  timeoutId1?: NodeJS.Timeout;
  timeoutId2?: NodeJS.Timeout;
  timeoutId3?: NodeJS.Timeout;
  timeoutId4?: NodeJS.Timeout;
  timeoutId5?: NodeJS.Timeout;

  constructor(
    private nodeService: NodeService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private _stepsFacade: StepsFacade,
    private _batchFacade: BatchFacade,
    private _productChainFacade: ProductChainFacade,
    private _route: ActivatedRoute,
    private _group: ProductChainGroup,
    private _suppliersFacade: SuppliersFacade,
    private _phasesFacade: PhasesFacade,
    private _router: Router,
    private changeDetection: ChangeDetectorRef,
    private _productsFacade: ProductsFacade,
    private _dataStorageService: DataStorageService,
    private _sidenavStateService: SidenavService
  ) {
    this.direction = 'topBottom';
    this.form = this._group.addChain;

    this._router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.batchSetup = e.url.includes('/batch-management');
      }
    });
  }
  ngOnDestroy(): void {
    if (this.timeoutId1) clearTimeout(this.timeoutId1);
    if (this.timeoutId2) clearTimeout(this.timeoutId2);
    if (this.timeoutId3) clearTimeout(this.timeoutId3);
    if (this.timeoutId4) clearTimeout(this.timeoutId4);
    if (this.timeoutId5) clearTimeout(this.timeoutId5);
  }

  ngOnInit() {
    const uuid = this._route.snapshot.paramMap.get('id');
    this.product_chain_uuid = this._route.snapshot.paramMap.get('id');
    if (uuid) {
      this.getProductChain(uuid);
    }

    this._productsFacade.updateSupplierList.subscribe(res => {
      if (res) {
        this.getSupplierList();
      }
    });

    this.getSupplierList();
    if (this.timeoutId1) clearTimeout(this.timeoutId1);
    this.timeoutId1 = setTimeout(() => {
      this.isOpen = true;
    }, 200);
  }
  setData() {
    const uuid = this._route.snapshot.paramMap.get('id');
    this.product_chain_uuid = this._route.snapshot.paramMap.get('id');
    if (uuid) {
      this.getProductChain(uuid);
    }

    this._productsFacade.updateSupplierList.subscribe(res => {
      if (res) {
        this.getSupplierList();
      }
    });

    this.getSupplierList();
    if (this.timeoutId1) clearTimeout(this.timeoutId1);
    this.timeoutId1 = setTimeout(() => {
      this.isOpen = true;
    }, 200);
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.nodeService.selectedStepData$.subscribe((node: INode | null) => {
      if (node) {
        this.selectedStep = node;
        this.setEmailVariables();
        this.sidenav.open();
      } else {
        if (this.selectedChain?.uuid) {
          this.nodeService.clear();
          this.getProductChain(this.selectedChain.uuid);
        }
        this.sidenav.close();
      }
    });

    this.nodeService.completeStepData$.subscribe((node: INode | null) => {
      if (node) {
        this.showCompleteStepSidenav = true;
        this.setEmailVariables();
        this.sidenav.open();
      } else {
        if (this.selectedChain?.uuid) {
          this.nodeService.clear();
          this.getProductChain(this.selectedChain.uuid);
        }
        this.sidenav.close();
        this.showCompleteStepSidenav = false;
      }
      this.changeDetection.detectChanges();
    });

    this.nodeService.selectedPhase$.subscribe((data: string | null) => {
      if (data) {
        const type_step = this.selectedChain?.type_steps.find(typeStep => typeStep.name === data);
        const nodeList = Array.from(document.querySelectorAll('.node')) as HTMLElement[];

        nodeList.forEach((node: HTMLElement) => {
          node.classList.remove('disabled');
          if (node.dataset['type_step'] !== type_step?.uuid) {
            node.classList.add('disabled');
          }
        });
      } else {
        document.querySelectorAll('.node').forEach((node: Element) => {
          node.classList.remove('disabled');
        });
      }
    });

    this.nodeService.connectionDeleted$.subscribe((data: boolean) => {
      if (data) {
        this.saveChain();
      }
    });

    this.nodeService.stepDeleted$.subscribe((data: boolean) => {
      if (data) {
        this.fetchProductChain();
      }
    });

    this.nodeService.stepMoved$.subscribe((data: boolean) => {
      if (data) {
        this.saveChain();
      }
    });

    this.nodeService.jsPlumbInstance.bind('connectionDetached', (info: unknown, originalEvent: MouseEvent) => {
      if (originalEvent) {
        if (this.timeoutId2) clearTimeout(this.timeoutId2);
        this.timeoutId2 = setTimeout(() => {
          this.saveChain();
        }, 500);
      }
    });

    this.nodeService.jsPlumbInstance.bind('connection', (info: unknown, originalEvent: MouseEvent) => {
      if (originalEvent) {
        if (this.timeoutId3) clearTimeout(this.timeoutId3);
        this.timeoutId3 = setTimeout(() => {
          this.saveChain();
        }, 500);
      }
    });
    this.browserWidth = window.innerWidth;
    this.getBrowserWidth();
  }

  getProductChain(uuid: string): void {
    if (this.batchSetup) {
      this._batchFacade.getBatch$(uuid).subscribe({
        next: this._getProductChainSuccess.bind(this),
        error: this._error.bind(this),
      });
    } else {
      this._productChainFacade.getProductChain$(uuid).subscribe({
        next: this._getProductChainSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  getSupplierList(): void {
    this._suppliersFacade.getSupplierList$('name', 999999).subscribe({
      next: this.supplierListSuccess.bind(this),
      error: this.errorResponse.bind(this),
    });
  }

  fetchProductChain(): void {
    if (this.selectedChain?.uuid) {
      this.nodeService.clear();
      this.getProductChain(this.selectedChain.uuid);
    }
  }

  setUpChainView() {
    this.nodeService.setRootViewContainerRef(this.viewContainerRef);

    this.nodes.forEach(node => {
      this.nodeService.addDynamicNode(node, this.direction);
    });
    if (this.timeoutId4) clearTimeout(this.timeoutId4);
    this.timeoutId4 = setTimeout(() => {
      this.connections.forEach((connection: INodeConnection) => {
        this.nodeService.addConnection(connection);
      });
    }, 500);
  }
  addStep() {
    const dialogRef = this.dialog.open(StepDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: { supplierList: this.supplierList },
    });

    let offsetTop = 20;
    let offsetLeft = 20;
    const nodeList = Array.from(document.querySelectorAll('.node')) as HTMLElement[];

    nodeList.forEach((node: HTMLElement) => {
      if (node.offsetTop >= 20 && node.offsetTop <= 100) {
        offsetTop = node.offsetTop + 20;
        offsetLeft = node.offsetLeft + 20;
      }
    });

    dialogRef.afterClosed().subscribe((result: Partial<INode>) => {
      if (result) {
        const step = {
          name: result.name,
          position_x: offsetTop,
          position_y: offsetLeft,
          description: result.description ? result.description : '',
          ...(result.supplier ? { supplier: result.supplier } : {}),
          product_chain: this.selectedChain?.uuid,
        };

        this._stepsFacade.createStep$({ ...step }).subscribe(data => {
          const addedStep = data;
          addedStep.id = data.uuid;
          this.nodeService.addDynamicNode(addedStep, this.direction);
          if (this.selectedChain) {
            this.selectedChain.steps = [...this.selectedChain.steps, data];
          }
        });
      }
    });
  }

  changeDirection(direction: string) {
    this.direction = direction;
    this.saveChain();
    this.nodeService.clear();
    this.setUpChainView();
  }

  zoom(zoom: string) {
    zoom === '+' ? (this.currentZoom += 0.2) : (this.currentZoom -= 0.2);
    this.nodeService.zoom(this.currentZoom);
  }

  clearAll() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to remove all steps and connections from this Product Chain?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.selectedChain) {
        this.nodeService.clear();
        this._productChainFacade
          .updateProductChain$({ steps: [], links_steps: [], direction: this.direction }, this.selectedChain?.uuid)
          .subscribe(() => {
            this._snackbarService.openTypeSnackbar(
              `All Steps and Connections has been removed`,
              NotificationType.success
            );
          });
      }
    });
  }

  close() {
    this.sidenav.close();
  }

  saveChain(close?: boolean) {
    this.savingChain = true;
    //save element position on Canvas and node conections
    const container = this.viewContainerRef.element.nativeElement.parentNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nodes = Array.from(container.querySelectorAll('.node')).map((node: any) => {
      return {
        id: node.id,
        name: node.dataset.name,
        description: node.dataset.desc,
        position_x: node.offsetTop,
        position_y: node.offsetLeft,
        supplier: node.dataset.supplier,
        type_step: node.dataset.type_step,
        manufactories: node.dataset.manufactories,
        ingredient: node.dataset.ingredient,
        product_chain: node.dataset.product_chain,
        phase: node.dataset.phase,
        uuid: node.id,
        supplier_batch_number: node.supplier_batch_number,
      };
    });

    const connections = this.nodeService.jsPlumbInstance.getAllConnections().map((conn: IGetConnection) => ({
      uuids: conn.getUuids(),
    }));

    const links_steps = this.formatConnectionsBeforePost(connections);

    if (this.selectedChain?.uuid) {
      this._productChainFacade
        .updateProductChain$({ steps: nodes, links_steps, direction: this.direction }, this.selectedChain?.uuid)
        .subscribe({
          next: () => {
            this.savingChain = false;
            if (close) {
              this._router.navigate(['/dashboard']);
            }
          },
          error: this._error.bind(this),
        });
    }
  }

  formatConnectionsBeforePost(connections: INodeConnection[]): IProductChainLink[] {
    return connections.map((connection: INodeConnection) => {
      return {
        step_from: this.removeSuffixFromId(connection.uuids[0]),
        step_to: this.removeSuffixFromId(connection.uuids[1]),
      };
    });
  }

  removeSuffixFromId(uuid: string): string {
    const isTop = uuid.includes('_top');
    const index = uuid.indexOf(isTop ? '_top' : '_bottom');
    const finalString = uuid.substring(0, index);

    return finalString;
  }

  formatConnections(connections: IProductChainLink[]): INodeConnection[] {
    return connections.map((connection: IProductChainLink) => {
      return {
        uuids: [`${connection.step_from}_bottom`, `${connection.step_to}_top`],
      };
    });
  }

  updateProductChainName(): void {
    const changedFormValues: Partial<IProductChain> = GetDirtyValues(this.form);
    if (this.form.valid && this.selectedChain) {
      this._productChainFacade.updateProductChain$({ ...changedFormValues }, this.selectedChain?.uuid).subscribe({
        next: this._updateProductChainSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  createCluster(cluster?: ICluster): void {
    const dialogRef = this.dialog.open(CreateClusterComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        product_chain: this.selectedChain,
        selectedSteps: cluster ? this.selectedStepsList : [],
        cluster: cluster,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.selectedChain?.uuid) {
        this.nodeService.clear();
        this.getProductChain(this.selectedChain?.uuid);
      }
    });
  }

  canvasClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (target?.classList.contains('canvas-playground')) {
      const selectedList = document.querySelectorAll('.selected');
      document.querySelectorAll('.node').forEach((node: Element) => {
        node.classList.remove('selected');
        node.querySelector('i')?.classList.add('hidden');
        selectedList.length &&
          this.nodeService.jsPlumbInstance.removeFromPosse(document.getElementById(`${node.id}`), 'posse');
      });
    }
  }

  closeDialog(event?: boolean): void {
    event && this.ngOnInit();
    this.sidenav.close();
  }

  updateBatchStatus(isDeactivate: boolean): void {
    const dialogRef = this.dialog.open(BatchStatusUpdateComponent, {
      width: '580px',
      height: '460px',
      data: { isDeactivate, batchName: this.selectedChain?.batch[0].name, isMarkStep: true },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.selectedChain?.batch.length) {
        this._batchFacade
          .updateBatchChain$(this.selectedChain.uuid, {
            name: this.selectedChain.batch?.[0]?.name,
            is_active: !isDeactivate,
            status: 'COMPLETE',
          })
          .subscribe({
            next: this._successUpdateBatchStatus.bind(this),
            error: this._error.bind(this),
          });
      }
    });
  }

  _successUpdateBatchStatus(): void {
    this.fetchProductChain();
  }

  getProduct(): void {
    if (this.productUUID) {
      this._productsFacade.getProduct$(this.productUUID).subscribe({
        next: this._setProduct.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _setProduct(response: IProductResponse): void {
    this.product = response;
  }

  publish(): void {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    const isAmway = brandName?.toLowerCase() === 'amway';
    if (isAmway && this.selectedChain?.uuid) {
      this._router.navigate([`/publish/${this.selectedChain?.uuid}/custom-publications/amway`], {
        queryParams: { product: `${this.selectedChain?.product.uuid}` },
      });
    } else {
      const canPublish = this.selectedChain?.steps.length && this.selectedChain?.steps.length >= 3;

      if (canPublish) {
        sessionStorage.removeItem('publish_step');
        if (this.product_chain_uuid)
          this._productChainFacade.publishProductChain$(this.product_chain_uuid).subscribe({
            next: this.publishSuccess.bind(this),
            error: this._error.bind(this),
          });
      } else {
        this.dialog.open(InfoDialogComponent, {
          width: infoDialogWidth,
          height: infoDialogHeight,
          data: {
            infoText:
              'Sorry it is not possible to publish this chain, as the minimum requirements are to create at least 3 steps in the chain.',
            descriptionText:
              'We advise you to publish the chain once you have identified all the steps you need for the publication.',
            btnText: 'OK, got it',
            type: 'info-nowrap',
          },
        });
      }
    }
  }

  preview(): void {
    window.open(`publish/${this.product_chain_uuid}/preview-publication`, '_blank');
  }
  publishSuccess(response: IProductChainResult) {
    this._router.navigate([`/publish/${this.product_chain_uuid}/publications/finished`], {
      state: { product: this.product, reference: response?.reference },
    });
  }

  setEmailVariables(): void {
    const storedUser: IUser = this._dataStorageService.get(USER, Storage.local)
      ? this._dataStorageService.get(USER, Storage.local)
      : this._dataStorageService.get(USER, Storage.session);
    this.emailVariables = {
      'Brand contact name': storedUser.email,
      'Product name': this.selectedChain?.product.name,
      'Project name': this.selectedChain?.product.project?.name ?? null,
    };
  }
  private _getProductChainSuccess(data: IProductChainResult | IBatchResult): void {
    this.selectedChain = data;
    this.productUUID = data.product?.uuid;
    this.phases = this._phasesFacade.getChartTypeSteps(this.selectedChain.type_steps);
    // this.phases = this._phasesFacade.getRespectcyclePhasesWithoutLabels$(
    //   data.batch.length ? data.batch?.[0]?.batch_chain.product_chain : data.uuid
    // );
    this.form.patchValue({ name: data.name });
    this.has_inactive_steps = this.selectedChain.steps.some(step => !step.is_active);

    if (!this.selectedChain.steps || this.selectedChain.steps.every(step => step.is_active)) {
      this.show_deactivated = false;
    }
    const filteredSteps = this.batchSetup
      ? this.show_deactivated
        ? this.selectedChain.steps
        : this.selectedChain.steps.filter(step => step.is_active)
      : this.selectedChain.steps;
    this.nodes = filteredSteps.map(step => {
      return {
        ...step,
        product_chain: this.selectedChain?.uuid,
        isChainComplete: this.selectedChain?.status === ChainStatus.complete,
      };
    });

    this.connections = this.formatConnections(this.selectedChain.links_steps);
    this.direction = data.direction ? data.direction : 'topBottom';
    this.isEditChainName = false;
    this.setUpChainView();
    this.isChainComplete = this.selectedChain.status === ChainStatus.complete;
    if (this.selectedChain.status === ChainStatus.complete) {
      this.dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText: this.batchSetup
            ? 'Please note that this batch cannot be edited anymore because it is completed.'
            : 'Please note that this supply chain is completed and therefore it cannot be edited.',
          descriptionText: this.batchSetup
            ? ''
            : 'However, in order to edit it again, we invite you to first change its status to "In Progress".',
          btnText: 'OK, got it',
          type: 'forbidden_info_blue',
        },
      });
    }
    this.getProduct();
  }

  supplierListSuccess(data: ISuppliersResponse): void {
    this.supplierList = data.results;
  }
  refresh(): void {
    this.nodeService.clear();
    this.ngOnInit();
  }
  errorResponse(error: ISuppliersErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _updateProductChainSuccess(data: IProductChainResult): void {
    this.selectedChain = data;
    this.form.patchValue({ name: data.name });

    this.isEditChainName = false;
  }

  private _error(error: IProductChainErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.browserWidth = window.innerWidth;
    this.getBrowserWidth();
  }

  getBrowserWidth(): void {
    // Number of how many clusters to be shown. 4 is calculated number according to the witdh of the screen that is not used for the clusters. 150 is width of the cluster.
    const number = this.browserWidth / 150 - 4;
    this.displayClusters = number | 0;
  }

  setToggle(isSet: boolean) {
    if (this.timeoutId5) clearTimeout(this.timeoutId5);
    this.timeoutId5 = setTimeout(() => {
      this.isToggleSet = isSet;
    }, 400);
  }

  showDeactivatedSteps(event: MatCheckboxChange): void {
    this.show_deactivated = event.checked;
    this.fetchProductChain();
  }

  isCollapsed(isCollapsed: boolean) {
    this.isCollapsedSideNav = isCollapsed;
    this._sidenavStateService.setSidenavOpen(isCollapsed);
  }
}
