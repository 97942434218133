import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NotificationType } from '@app/core/constants';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-supplier-publication-setup',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './supplier-publication-setup.component.html',
  styleUrl: './supplier-publication-setup.component.scss',
})
export class SupplierPublicationSetupComponent implements OnInit, OnChanges {
  @Input() supplier!: ISupplierResponse | ISupplier;
  @Output() refreshSupplier = new EventEmitter<boolean>();

  form!: UntypedFormGroup;
  alreadySubmitted = false;
  readonly #supplierService = inject(SuppliersService);
  readonly #supplierFacade = inject(SuppliersFacade);
  readonly #snackBarService = inject(SnackbarService);

  initialFormValue?: {
    selectInput: string;
    is_name_private: boolean;
    is_description_private: boolean;
  };
  private initialInputValue!: number;

  selectOptions = [
    { value: 4, expVal: 'street name, city name, region name, country name', viewValue: 'Address' },
    { value: 3, expVal: 'city name', viewValue: 'City' },
    { value: 2, expVal: 'region name', viewValue: 'Region' },
    { value: 1, expVal: 'country name', viewValue: 'Country' },
  ];

  selectedOption?: string;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      selectInput: [''],
      is_name_private: [false],
      is_description_private: [false],
      is_hidden: [false],
    });

    this.form.get('is_name_private')?.valueChanges.subscribe((is_name_private: boolean) => {
      const selectInputControl = this.form.get('selectInput');
      if (is_name_private) {
        selectInputControl?.disable();
        this.form.get('selectInput')?.setValue(this.selectOptions[3]);
      } else {
        selectInputControl?.enable();
        this.form.get('selectInput')?.setValue(this.selectOptions[this.initialInputValue - 1]);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier']) {
      this.patchForm();
      this.initialFormValue = this.form.value;
      this.initialInputValue = this.form.controls['selectInput'].value.value;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.form.dirty && !this.isFormUnchanged()) {
      $event.returnValue = true;
    }
  }

  ngOnInit(): void {
    this.patchForm();
    // Save the initial form value for further check.
    this.initialFormValue = this.form.value;
    // Change the value into Supplier service if there is a change into the form.
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty && !this.isFormUnchanged() && !this.alreadySubmitted) {
        this.#supplierService.changesBehaviour.next(true);
      } else this.#supplierService.changesBehaviour.next(false);
    });
  }

  patchForm() {
    this.form.patchValue({
      is_name_private: this.supplier.is_name_private,
      is_description_private: this.supplier.is_description_private,
      selectInput: this.selectOptions.find(row => row.value == this.supplier.geopositioning),
    });
  }

  onSubmit() {
    const payload = { ...this.form.value, geopositioning: this.form.controls['selectInput'].value.value };
    this.#supplierFacade.updateSupplier$(this.supplier.uuid, payload).subscribe({
      next: () => {
        this.#supplierService.changesBehaviour.next(false);
        this.alreadySubmitted = true;
        this.refreshSupplier.emit(true);

        this.#snackBarService.openTypeSnackbar('Publication setup updated successfully', NotificationType.success);
      },
      error: () => {
        this.#snackBarService.openTypeSnackbar(
          'Error, please contact our support for further details',
          NotificationType.error
        );
      },
    });
  }

  // Check if the form has a change or has a initial value
  isFormUnchanged(): boolean {
    // Compare the initial form value with the current form value
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.form.value);
  }

  onToggleChange(field: 'is_name_private' | 'is_description_private', event: MatSlideToggleChange) {
    if (field === 'is_name_private') {
      this.form.get('is_hidden')?.setValue(!event.checked);
    }
    this.form.get(field)?.setValue(!event.checked);
    this.#supplierService.changesBehaviour.next(true);
  }
}
