<form [formGroup]="form">
  <div class="col-6">
    <app-file-upload
      *ngIf="mode"
      [placeholder]="imageUrl ? imageUrl : ('Upload product image' | translate)"
      [iconType]="'add_photo_alternate'"
      [imageUrl]="imageUrl"
      (fileData)="saveImageData($event)"
      [status]="form.controls['image'].status"
      [title]="'Edit Product' | translate"
      [uploadImageText]="'Upload product photo' | translate"
    >
    </app-file-upload>
    <app-file-upload
      *ngIf="!mode"
      [placeholder]="'Upload product image' | translate"
      [iconType]="'add_photo_alternate'"
      (fileData)="saveImageData($event)"
      [status]="form.controls['image'].status"
      [uploadImageText]="'Upload product image' | translate"
    >
    </app-file-upload>
  </div>
  <div class="fields-section">
    <!-- <div class="d-flex gap row"> -->
    <div class="row">
      <mat-form-field class="input-primary col-6" appearance="outline">
        <mat-label>{{ 'Product Name' | translate }}</mat-label>
        <input matInput formControlName="name" placeholder="Product Name" />
        <mat-error *ngIf="form.controls['name'].hasError('required')">{{
          'please enter product name in order to proceed' | translate
        }}</mat-error>
      </mat-form-field>
      <!-- <div class="col-6" ></div> -->
      <div class="input-primary rs-rte-wrap wrp col-6" appearance="outline">
        <div class="wrp">
          <app-rs-rte
            formControlName="description"
            [c]="$any(form.controls['description'])"
            [placeholder]="'Product Description' | translate"
          ></app-rs-rte>
        </div>
      </div>
      <div class="col-6"></div>
      <mat-form-field class="input-primary w-48 col-6" appearance="outline">
        <mat-label>{{ 'Product Reference Number' | translate }}</mat-label>
        <input matInput formControlName="reference" placeholder="{{ 'Product Reference Number' | translate }}" />
      </mat-form-field>
    </div>
    <!-- <div class="row">
      <mat-form-field class="input-primary w-48 col-6" appearance="outline">
        <mat-label>{{ 'Product Reference Number' | translate }}</mat-label>
        <input matInput formControlName="reference" placeholder="{{ 'Product Reference Number' | translate }}" />
      </mat-form-field>
    </div> -->
  </div>
</form>
