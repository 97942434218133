import {
  Component,
  Input,
  forwardRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RsRteComponent),
  multi: true,
};

@Component({
  selector: 'app-rs-rte',
  templateUrl: './rs-rte.component.html',
  styleUrls: ['./rs-rte.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
})
export class RsRteComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() formControlName = '';
  @Input() height = '';
  @Input() placeholder = '';
  @Input() c: FormControl = new FormControl();
  @ViewChild('ckeditor') ckeditorRef!: ElementRef;
  @ViewChild('ckeditor', { static: false }) ckeditor!: CKEditorComponent;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  showToolbar = false;
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
      shouldNotGroupWhenFull: true,
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
  };
  public noEditorConfig = {
    toolbar: {
      items: [],
      shouldNotGroupWhenFull: true,
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
  };

  public editorStyle = {
    height: '100%',
    maxHeight: '100%',
  };

  errors: Array<string> = ['This field is required'];

  constructor(private renderer: Renderer2) {}
  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  ngAfterViewInit(): void {
    this.c.valueChanges.subscribe(() => {
      if (this.c.value === '' || this.c.value === null || this.c.value === undefined) {
        this.innerValue = '';
        if (this.ckeditorRef.nativeElement) {
          this.ckeditorRef.nativeElement.value = '';
        }
      }
    });
  }

  onReady(): void {
    if (this.ckeditorRef && this.ckeditorRef.nativeElement) {
      this.renderer.listen(this.ckeditorRef.nativeElement, 'click', e => {
        const target = e.target as HTMLAnchorElement;
        if (target.tagName === 'A' && target.href) {
          e.preventDefault();
          window.open(target.href, '_blank');
        }
      });
    }
  }

  private innerValue = '';

  onChange(value: string): void {
    this.innerValue = value;
    this.propagateChange(this.innerValue);

    this.errors = [];

    for (const key in this.c.errors) {
      if (Object.prototype.hasOwnProperty.call(this.c.errors, key)) {
        if (key === 'required') {
          this.errors.push('This field is required');
        } else {
          this.errors.push(this.c.errors[key]);
        }
      }
    }
  }

  onEditorChange(event: { editor: { getData: () => string } }): void {
    const newValue = event.editor.getData();
    this.onChange(newValue);
  }

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  propagateChange: (value: string) => void = () => {
    // no empty
  };

  writeValue(value: string): void {
    this.innerValue = value;
  }

  registerOnChange(fn: (value: string) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {
    // no empty
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.c) {
      isDisabled ? this.c.disable() : this.c.enable();
    }
  }
  toggleToolbar() {
    this.showToolbar = !this.showToolbar;
    if (this.showToolbar) {
      // Clear any existing timeout if it's still pending
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.ckeditor && this.ckeditor.editorInstance) {
          const editor = this.ckeditor.editorInstance;
          editor.focus();
          const model = editor.model;
          const modelRoot = model.document.getRoot();
          model.change(writer => {
            if (modelRoot) {
              const endPosition = writer.createPositionAt(modelRoot, 'end');
              writer.setSelection(endPosition);
            }
          });
        }
      }, 0);
    }
  }
}
