import {
  IBrandFeaturesMessage,
  IBrandFeaturesResponse,
  IBrandListResult,
  IBrandUserInvite,
  IBrandUserReinvite,
  IBrandUserResult,
  IBrandUsersResponse,
  IBrandsListResponse,
  IDeleteBrandUser,
  IDownloadUserData,
  ISubjectRight,
  ISuppBrandFeatureResponse,
} from '@app/core/interface/brands.interface';

import { BrandsService } from '@service/brands.service';
import { ICreateBrandResponse } from '../interface/register.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { map } from 'rxjs/operators';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class BrandsFacade {
  constructor(private _brandsService: BrandsService) {}

  getBrands$(): Observable<IBrandsListResponse> {
    return this._brandsService.getBrands$().pipe(
      take(1),
      catchError(({ error }: IBrandsListResponse): ObservableInput<IBrandsListResponse> => throwError(() => error))
    );
  }

  updateBrand$(data: Partial<IBrandListResult>, uuid: string): Observable<IBrandsListResponse> {
    const formData = generateFormData(data);

    return this._brandsService.updateBrand$(formData, uuid).pipe(
      take(1),
      switchMap(() => {
        return this.getBrands$();
      }),
      catchError(({ error }: IBrandsListResponse): ObservableInput<IBrandsListResponse> => throwError(() => error))
    );
  }

  getBrandUsers$() {
    return this._brandsService.getBrandUsers$().pipe(
      take(1),
      catchError(({ error }: IBrandUsersResponse): ObservableInput<IBrandUsersResponse> => throwError(() => error))
    );
  }

  updateBrandUser$(data: Partial<IBrandUserResult>, uuid: string): Observable<IBrandUsersResponse> {
    const formData = generateFormData(data);

    return this._brandsService.updateBrandUser$(formData, uuid).pipe(
      take(1),
      catchError(({ error }: IBrandUsersResponse): ObservableInput<IBrandUsersResponse> => throwError(() => error))
    );
  }

  inviteBrandUser$(data: IBrandUserInvite): Observable<IBrandUserResult> {
    const formData = generateFormData(data);

    return this._brandsService.inviteBrandUser$(formData).pipe(
      take(1),
      catchError(({ error }: IBrandUserResult): ObservableInput<IBrandUserResult> => throwError(() => error))
    );
  }

  resendInvite$(data: IBrandUserReinvite): Observable<IBrandUserReinvite> {
    const formData = generateFormData(data);

    return this._brandsService.resendInvite$(formData).pipe(
      take(1),
      catchError(({ error }: IBrandUserReinvite): ObservableInput<IBrandUserReinvite> => throwError(() => error))
    );
  }

  deleteBrandUser$(uuid: string): Observable<IDeleteBrandUser> {
    return this._brandsService.deleteBrandUser$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteBrandUser): ObservableInput<IDeleteBrandUser> => throwError(() => error))
    );
  }

  getBrand$(uuid: string): Observable<IBrandListResult> {
    return this._brandsService.getBrand$(uuid).pipe(
      take(1),
      catchError(({ error }: IBrandListResult): ObservableInput<IBrandListResult> => throwError(() => error))
    );
  }

  switchBrand$(data: { brand: string }): Observable<ICreateBrandResponse> {
    const formData = generateFormData(data);

    return this._brandsService.switchBrand$(formData).pipe(
      take(1),
      catchError(({ error }: ICreateBrandResponse): ObservableInput<ICreateBrandResponse> => throwError(() => error))
    );
  }
  getBrandFeatures$(): Observable<IBrandFeaturesResponse> {
    return this._brandsService.getBrandFeatures$().pipe(
      take(1),
      catchError(({ error }: IBrandFeaturesResponse): Observable<IBrandFeaturesResponse> => throwError(() => error))
    );
  }
  getSuppBrandFeatures$(): Observable<IBrandFeaturesResponse> {
    return this._brandsService.getSuppBrandFeatures$().pipe(
      take(1),
      catchError(({ error }: IBrandFeaturesResponse): Observable<IBrandFeaturesResponse> => throwError(() => error))
    );
  }

  // getBrandFeaturesByUuid$
  getBrandFeaturesByUuid$(uuid: string): Observable<ISuppBrandFeatureResponse> {
    return this._brandsService.getBrandFeaturesByUuid$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ISuppBrandFeatureResponse): Observable<ISuppBrandFeatureResponse> => throwError(() => error)
      )
    );
  }

  enableBrandFeatures$(uuid: string): Observable<IBrandFeaturesMessage> {
    return this._brandsService.enableBrandFeatures$(uuid).pipe(
      take(1),
      catchError(({ error }: IBrandFeaturesMessage): Observable<IBrandFeaturesMessage> => throwError(() => error))
    );
  }

  disableBrandFeatures$(uuid: string): Observable<IBrandFeaturesMessage> {
    return this._brandsService.disableBrandFeatures$(uuid).pipe(
      take(1),
      catchError(({ error }: IBrandFeaturesMessage): Observable<IBrandFeaturesMessage> => throwError(() => error))
    );
  }

  getNumberOfBrands$(): Observable<number> {
    return this.getBrands$().pipe(
      map(brandsResponse => {
        if (brandsResponse && brandsResponse.results) {
          return brandsResponse.results.length;
        } else {
          return 0;
        }
      })
    );
  }

  getBrandUserSubjectRights$(uuid: string): Observable<ISubjectRight[]> {
    return this._brandsService.getBrandUserSubjectRights$(uuid).pipe(
      take(1),
      catchError((error): ObservableInput<ISubjectRight[]> => throwError(() => error))
    );
  }

  createSubjectRightsRequest$(data: Partial<ISubjectRight>, uuid: string): Observable<ISubjectRight> {
    const formData = generateFormData(data);

    return this._brandsService.createSubjectRightsRequest$(formData, uuid).pipe(
      take(1),
      catchError((error): ObservableInput<ISubjectRight> => throwError(() => error))
    );
  }

  downloadUserData$(): Observable<IDownloadUserData> {
    return this._brandsService.downloadUserData$().pipe(
      take(1),
      catchError((error): ObservableInput<IDownloadUserData> => throwError(() => error))
    );
  }
}
